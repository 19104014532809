
<!-- index.vue 只作为整个页面的中间部分存在 -->
<template>
	<div class="container">
		<div class="titlediv">
			<!-- <span class="titletag" tabindexa="309">&nbsp;</span> -->
			<el-image :src="require('@/assets/log2l.svg')" style=" height: 20px; width: 20px;"></el-image>
			<span style="fontSize:22px; color:#6972f3;">(沙塔)</span>
			<span class="title" style="fontSize: 22px "><strong> 系统简介 </strong></span>
		</div>
		<div class="paragraph">
			<!-- 文档章节链接 -->
			<p>
				<el-link href="javascript:void(0);" :underline="false" style="fontSize:20px" @click.native="p1click()">
					一、系统起源</el-link><br />
				<el-link href="javascript:void(0);" :underline="false" style="fontSize:20px" @click.native="p2click()">
					二、功能概述</el-link><br />
				<el-link href="javascript:void(0);" :underline="false" style="fontSize:20px" @click.native="p3click()">
					三、开发说明</el-link><br />
				<el-link href="javascript:void(0);" :underline="false" style="fontSize:20px" @click.native="p4click()">
					四、募员与广告</el-link><br />
			</p>
			<div id="p1" style="overflow:auto">
				
					<h1>
						<el-link href="javascript:void(0);" :underline="false" style="fontSize:20px"
							@click.native="p1click()">
							<font face="华文中宋" color="#009900"><strong>系统起源</strong></font>
						</el-link>
					</h1>
				
				<div id="p1b" style="overflow:auto" v-show="p1bShow">
					<p>
						<span><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统的起源 &nbsp;
							</strong></span>
						<span>很早的时候，我就有一个梦：希望能自由的从事自己感兴趣的工作。我喜欢软件开发、数据库编程、金融投资等方面的工作，但在现实的世界里，很多年来，为了生活，自己不得不从事一系列和自己的喜好并不十分对口的工作。近两年来，很庆幸终于能干自己喜欢的软件开发方面的工作，但人已不再年轻，掌握的技能早已跟不上时代的发展，自己的能力也很有限，感觉很难自己完成一个优秀的、有点价值的软件产品或系统。即使这样，我也在工作生活之余努力保持学习，学习了后端一系列的软件开发技术,也经过一个多月的时间学习了解了一下前端Vue的相关技术。借助于网络上有奉献精神的网友提供的开源代码，搭建了这个简陋的玩具系统。
						</span>
					</p>
					<p>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上面说过，我喜欢学习了解金融方面的知识，很多年前上大学的时候就选修
							过证券投资的课程，那时教我们课程的老师也炒股，他有一句话深深的印在我脑海中：“中国的股市不是很规范，不要轻易炒股”。所以学完后的上十年我只是经常关注股市，从未进入，直到2006年，看到股市发展火爆，终于按捺不住，冲入股市真枪实弹的体验了一下炒股，结果经历了08年的股灾最后小亏了点退出。
							多年来的生活经历，让我深深的感受到：现在的社会，没有关系和背景，一个生活在武汉这样的城市的普通人，想过得富裕幸福，还是比较难的。富裕与我们普通阶层大多数人还是无关的。独自创业，失败的应该还是大多数；搞第二产业，下班后摆个摊，赚不赚钱不说，城管允不允许还是问题；跑下滴滴出租，估计精力也很难支持。大多数普通人的出路，感觉也只有节衣缩食，精打细算，投资理财，赚点小钱补贴家用(当然这是建立在储蓄了点小钱的基础上)。对大多数人来说，最好还是投资理财产品，购买货币型基金，安全性很高，股票型基金的风险还是有点大。如果万一想靠证券股票投资，千万不要碰期货，股票也是尽量不搞为妙，我们大多数人还是玩不过庄家、证券公司、私募基金以及内幕人士。万一操作股票，也尽量低频次进行，目标也不要太高，所有的资金，如果一年10个点的盈利能力，那将是比理财产品多几倍的收益。如果能做到上十年累积效应，那也将是致富的一个渠道。
						</span>
					</p>
					<p>
						<span><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名字的由来 &nbsp;
							</strong></span>
						<span>我还有一个梦：希望能在像Oracle、桥水投资那样的公司自由的工作，也希望能像这样的公司一样依靠技术的力量发展壮大，同时也希望中国出现这样的投资公司能屹立于世界知名金融公司之林，收割西方世界的财富而不是任由他们宰割或在国内宰割弱小的国民。对于这个梦想，我能做什么呢？
							我能力实在太有限了，什么都做不了，我目前能做的，仅仅能提供这个十分简陋的玩具系统，供自己和对股市感兴趣的个体投资者方便对股市的数据进行相关分析，从而利于他们决策选取股票和何时对股票进行交易。我正好有一点点编程的技术，喜欢金融投资知识，也热爱数据库技术和数据分析，因此综合这些兴趣爱好，利用无名英雄的部分开源代码，搭建了这个系统，我希望能借助这个玩具系统抛砖引玉，聚沙成塔，能汇聚在软件后端开发、前端开发、UI设计、数据分析、证券知识等各个领域理论实践经验丰富的业余爱好者，共同优化完善这个系统，让它发展壮大，为更多的弱小投资者服务。所以，系统的名称我采用了聚沙成塔这个词语的意义。世界知名的公司都有公司标识，虽然这个系统很简陋，但我也希望有一天，在爱好者的共同努力下变得知名，所以也想给这个系统设计一个标识，但用什么好呢？大道至简，很多伟大的公司的标识也很简单，我想有个符号，既简单，名字也不错，又能体现中华文明的特性就好，所以最初想用“中”这个字构图，但是希腊字幕中的Φ(/faɪ/)
							与之相像，便舍弃了，历史上能名震西方的朝代有秦朝、汉朝和唐朝。秦字、唐字不太好构图，汉朝的“汉”字比较简单，而且中文正好也叫“汉语、汉字”，也能体现中华文明的特征，所以就取“汉”去掉三点水构图，也就是这个符号“
						</span>
						<span>
							<el-image :src="require('@/assets/log3h.svg')" style=" height: 18px; width: 18px;">
							</el-image>
						</span>
						<span>
							”。希腊字母的读音“阿尔法、贝塔、德尔塔”
							这些听起来不错，这个系统又取自于“聚沙成塔”之意，所以就叫这个符号为“沙塔”，读起来感觉也不错，英译SandCastle。希望有一天，这个中国的“希腊字母”它能被世界认可和熟知！
						</span>
					</p>
				</div>
			</div>
			<div id="p2" style="overflow:auto">
				
				<h1>
					<el-link href="javascript:void(0);" :underline="false" style="fontSize:20px"
						@click.native="p2click()">
						<font face="华文中宋" color="#009900"><strong>功能概述及简要操作</strong></font>
					</el-link>
				</h1>
				
				<div id="p2b" style="overflow:auto" v-show="p2bShow">
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当前本系统的功能目标：主要实现数据的存储与展示和数据分析供本人自己。
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>(1) 数据存储</strong>
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如图所示，本功能可实现手工、自动存储股票最新信息、历史信息到数据库系统。普通注册用户不用关心，也没开放给用户，由管理员我负责。提供给大家的是能方便查阅某只股票存储后的历史和最新交易信息。目前，由于服务器性能较差，我只存储了不到300只股票的信息，当然，可以随时从5000多只股票中选取某些添加到存储信息列表，这样系统每天也会更新添加后的股票信息。
					</p>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/i1.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>(2) 数据展示</strong>
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统的基本功能，能查阅当前两市的5000多只股票的列表信息，能查阅某只股票的所有历史信息 (
						如上所述，由于购买的腾讯云服务器比较廉价，性能太弱，暂且只显示300只左右股票最近两年左右的历史信息，实际已存储300只股所有的历史数据，后续有广告如果有网友乐意赞助高性能服务器的话，可以方便放开限制，查询5000只股票的所有历史记录！此数据量巨大：平均几千只股票十几年的交易数据，需要高性能服务器支持！)。
					</p>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/i2.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/i3.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/i4.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>(3) 数据分析功能</strong>
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;由于本人在上班，且家有老小，事务繁多，只能用业余时间完成此玩具系统，故目前暂且只完成了成交量分析和均线分析两项功能。这两项可作为用户选股的参考要素之一。下面简要介绍一下功能和操作。
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>成交量分析：</strong>
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主要是股票放量分析。如图所示，选取分析周期、放大倍数和特征日期，系统会自动从目前的近300只股票中计算出符合要求的所有股票。比如特征日选昨天，分析周期选5天，放到倍数选3倍，那么系统会自动计算出昨天成交量是前5天平均成交量3倍的所有股票，当然，系统使用了类似责任链或管道模式，后续可以继续选择放量位置（高位、中位、低位）和放量后估计的涨跌情况，系统会进一步在上述结果中筛选符合要求的股票。这样，保守的用户可以选择低位放量的股票作为购买参考，激进的用户可选择中位购买。对系统自动计算出的股票，用户还可选择某一只，点击分析按钮，可查看此股票在某段时间内的历史价格和成交量的图形化记录，其中放量的时间点在成交量图上自动显示为红色方便用户查看。
					</p>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/i5.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>均线分析：</strong>
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;操作和放量分析类似，主要显示某个特征日前，连续多少天(例如图中的5天)中，在每个周期(例如图中的3天)内的均线连续上升的股票。同样可以继续选择操作是高、中、低位均线上升的特征。下面的均线图显示了5日、10日、20日、30日均线信息。可拖动滚动条放大图形，也可选择隐藏某日均线，方便用户自定义分析。
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;均线详情功能与均线分析功能类似，不过是展示数据的维度不同而已。
					</p>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/i6.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/i7.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>(4) 单个图形</strong>
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主要使用图形化手段展示用户关心的股票的几个核心数据。
					</p>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/i8.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>(5) 附录-上证指数分析截图</strong>
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;希望有专业人士加入做此类分析。
					</p>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/szzsfenxi.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/szcjl.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>
					<div style="overflow:auto; text-align:center">
						<span>
							<el-image :src="require('@/assets/szzsfz.jpg')" style=" width:900px; height:500px;"></el-image>
						</span>
					</div>

				</div>
			</div>
			<div id="p3" style="overflow:auto">
				
				<h1>
					<el-link href="javascript:void(0);" :underline="false" style="fontSize:20px"
						@click.native="p3click()">
						<font face="华文中宋" color="#009900"><strong>系统开发说明</strong></font>
					</el-link>
				</h1>
				
				<div id="p3b" style="overflow:auto" v-show="p3bShow">
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
						首先，真诚的感谢网友提供的开源框架(后台管理系统框架)，使我免去了重复造轮子的无聊工作，非管理部分功能全部由本人利用业余时间完成。
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) 本系统后台使用Java开发，涉及到 spring boot, mybatis-plus,
						redis等技术，前台使用Vue开发，涉及 Vue, Vuex, axios,
						element-ui等技术，服务器主要用的nginx，数据库mysql，购买的腾讯云服务器，域名系统。由于本人年龄偏大，超过40，技术成旧，但系统中用到的技术繁多，综合性强，有些新技术也是刚学没两个月(前端)，且这个玩具系统从开发，到测试，到前后端分离的系统在云服务器上的nginx中的部署皆由本人在业余时间独立完成，中间坎坷，无与相吐，实不容易！所以肯定bug众多，望用户谅解！
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
						本系统使用的数据，前期从其它开源网站弄了一部分，现在每天对接的也是网易开源网站数据，但数据种类、详情还是有限，不能获取想要分析的所有数据，但网易提供了最
						核心的每日交易数据，也很是感谢！
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
						目前系统只做了个人认为比较有价值的成交量、均线的角度做了分析，后期想从股票价格、换手率、市值、市盈率、板块特征等其他维度做分析，更希望以后有精力和能力将数据分析软件Origin
						结合进来，提供更精细和强大的分析功能。
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)
						本系统非商业性系统，供用户自由免费使用，没有任何价值值得黑客攻击勒索我这个廉价的打工族，我也不会为被黑而掏费用，系统有备份手段，最多花费我一点时间而已，所以请黑客高手手下留情，不要做伤人又不利己的事情，白白浪费双方的时间。
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)
						系统目前开放给用户自由注册免费使用，但如前所述，购买的是腾讯云廉价的服务器，目前的数据情况我个人使用都有点慢，所以如若用户过多系统崩溃，无法正常使用被迫关闭请用户谅解！本系统提供的是能让用户方便查看数据、进一步分析数据的功能，不做用户选股、交易保证，所以股市有风险，投资需谨慎，用户自己注意投资安全！
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)
						<strong >本系统需在电脑端运行，使用chrome浏览器运行(其它浏览器未测试可能存在样式问题)！</strong>
					</p>
				</div>
			</div>
			<div id="p4" style="overflow:auto;">
				
				<h1>
					<el-link href="javascript:void(0);" :underline="false" style="fontSize:20px"
						@click.native="p4click()">
						<font face="华文中宋" color="#009900"><strong>募员与广告</strong></font>
					</el-link>
				</h1>
				
				<div id="p4b" style="overflow:auto" v-show="p4bShow">
					<font color="red">
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
							要想成为一个稍有价值的系统，需要做的工作实在太多，比如增加用户留言或讨论功能，增加手机、用户名、微信QQ登陆，增加数据导出成Excel文件功能等，不胜枚举，单凭我个人的力量很难完成和做好。
						</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;所以如上所述：我希望能借助这个玩具系统抛砖引玉，
							聚沙成塔，能汇聚在系统架构设计、软件后端开发、前端开发、UI设计、数据分析、证券知识等各个领域理论实践经验丰富的业余爱好者，共同开发优化完善这个系统。希望有兴趣者加入，当然目前没有报酬，因为我也是免费供大家使用！
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)本人购买的腾讯云服务被用作Git服务器、文件服务器、数据库服务器、Web服务器，自己一人使用还勉强可以，如果多用户使用的话，性能实在太低，如有用户自愿赞助一台高性能服务器的话，该用户将永久免费享受在此服务器上的系统服务。
						</p>
						<p>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) 本人联系方式：329513737@qq.com ，
							平时上班忙，家务事也多，如没及时回复大家，敬请谅解！
						</p>
						<p>&nbsp; </p><p>&nbsp; </p><p>&nbsp; </p><p>&nbsp; </p><p>&nbsp; </p>
						
					</font>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			p1bShow: false,
			p2bShow: false,
			p3bShow: false,
			p4bShow: true
		}
	},
	methods: {
		p1click() {
			this.p1bShow = !this.p1bShow;
		},
		p2click() {
			this.p2bShow = !this.p2bShow;
		},
		p3click() {
			this.p3bShow = !this.p3bShow;
		},
		p4click() {
			this.p4bShow = !this.p4bShow;
		}

	}

}
</script>

<style scoped>
.titlediv {
	margin: 28px 0 0 2px;
	height: 32px;
	line-height: 32px;
	text-align: center;
}

.paragraph {
	margin: 10px;
	font-size: 16px;
	line-height: 32px;

}

.titletag {
	border-left: 2px solid #6972f3;
	width: 2px;
	height: 20px;
}

.title {
	font-family: "Microsoft Yahei";
	font-size: 20px;
	color: #6972f3;
	margin-left: 20px;
}

.box1 {
	color: royalblue;
	background-color: antiquewhite;
	border: 5px blue solid;
	border-right: 5px red solid;
	border-left: 8px orange solid;
	border-top: 10px green dotted;
	border-bottom: 1px firebrick double;

	padding-top: 10px;
	padding-right: 15px;
	padding-bottom: 20px;
	padding-left: 30px;

}

.container {
	width: 68%;
	margin: auto;
}
</style>