import Vue from 'vue'

//格式化数字，保留小数点后1位小数
Vue.filter("digFmtDot1", function (value) {
  if (value || value === 0) {
    //这个判断是为了防止数据为空，页面渲染为NAN
    return parseFloat(value).toFixed(1);
  }
})

Vue.filter("digFmtDot2", function (value) {
  if (value || value === 0) {
    //这个判断是为了防止数据为空，页面渲染为NAN
    return parseFloat(value).toFixed(2);
  }
})

Vue.filter("digFmtDot3", function (value) {
  if (value || value === 0) {
    //这个判断是为了防止数据为空，页面渲染为NAN
    return parseFloat(value).toFixed(3);
  }
})

Vue.filter("digFmtDot4", function (value) {
  if (value || value === 0) {
    //这个判断是为了防止数据为空，页面渲染为NAN
    return parseFloat(value).toFixed(4);
  }
})

Vue.filter("addPercent", function (value) {
  if (value || value === 0) {
    //这个判断是为了防止数据为空，页面渲染为NAN
    return value * 100 + '%';
  }
})



