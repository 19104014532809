import axios from "axios";
import router from "./router";
import Element from "element-ui"
import { del } from "vue";

 axios.defaults.baseURL = "https://www.kevinyp.top/stock"         //打包到部署服务器
// axios.defaults.baseURL = "http://localhost:9900/stock"          
//  axios.defaults.baseURL = "http://192.168.130.40:9900/stock"
//  axios.defaults.baseURL = "http://192.168.2.107:9900/stock"   //家里开发

const request = axios.create({
	timeout: 60000,
	headers: {
		'Content-Type': "application/json; charset=utf-8"
		// 'Content-Type': "application/x-www-form-urlencoded; charset=utf-8"
	}
})

request.interceptors.request.use(config => {
	config.headers['Authorization'] = localStorage.getItem("token")
	return config
})

request.interceptors.response.use(
	response => {
		console.log("response ->" + response)
		let res = response.data
		if (res.code === 200) {
			return response
		} else {
			Element.Message.error(!res.msg ? '系统异常' : res.msg)
			return Promise.reject(response.data.msg)
		}
	},
	error => {
		console.log(error)
		if (error.response.data) {
			error.massage = error.response.data.msg
		}
		if (error.response.status === 401) {
			//权限有问题则删除过期的token 2023-06-06
			window.localStorage.removeItem('token');
			router.push("/login")
		}
		Element.Message.error(error.massage, {duration: 3000})
		return Promise.reject(error)
	}
)

export default request