
<!-- Home.vue 作为整个单页面应用的页面，包含左侧区域，头部和下面的主页面(由子路由 index 构成) -->
<!-- App.vue 的组件中 路由(/) 对应的组件是 Home.vue,它将在 App.vue 的路由视图中显示 -->
<!-- 整体页面结构：  app.vue -> home.vue 登录后的页面
                                        -> SideMenu.vue
								        -> Tabs.vue
                                    ->index.vue (main部分，作为home的子路由存在)
							-> Login.vue  登录页面
     app.vue 中配置了两个路由，/  和 /login，分别对应显示上述的Home.vue 组件和 Login.vue组件。 -->

<template>
	<el-container class="global-container">
		<el-header>
				<el-row>
					<el-col :span="14">
						<div id="div-parent">
							<div id="div-head1">
								<span>
									<el-image :src="require('@/assets/log3h.svg')" style="height:25px; width:25px;">
									</el-image>
								</span>
							</div>
							<div id="div-head2">
								<strong style="margin-left:5px;">(沙塔)</strong>
								<strong style="margin-left:10px;">SandCastle System</strong>
							</div>
						</div>
					</el-col>
					<el-col :span="10">
						<div class="header-avatar">
							<el-avatar size="medium" :src="userInfo.avatar"></el-avatar>
							<el-dropdown>
								<span class="el-dropdown-link">
									<strong>{{ userInfo.username }}</strong><i
										class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item>
										<router-link :to="{ name: 'UserCenter' }">个人中心</router-link>
									</el-dropdown-item>
									<el-dropdown-item @click.native="logout">退出</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<!-- <div class= 'siteLink'> </div> -->
							<!-- <el-link  :underline="false" href="https://space.bilibili.com/13491144" target="_blank"><span class="siteLink">B站</span></el-link>		 -->
						</div>
					</el-col>
				</el-row>
		</el-header>

		<el-container class="main-container">
			<!-- <el-aside width="140px;overflow-x: hidden;overflow-y: auto;"> -->
			<el-aside :width="asideWidth+'px'">
				<SideMenu></SideMenu>
			</el-aside>
			<el-main :class="mainWidth">
				<Tabs></Tabs>
				<!-- <div style="width: 100%;height: calc(100% - 28px);overflow: hidden;"> -->
				<!-- <div style="width: 100%;height: calc(100% - 28px);overflow: hidden;">
					<router-view />
				</div> -->

				<!-- 子路由视图(/index) 所对应的组件 -->
				<router-view />
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import SideMenu from "./inc/SideMenu";
import Tabs from "./inc/Tabs";

export default {
	name: "Home",
	//声明使用的组件
	components: {
		SideMenu, Tabs
	},
	data() {
		return {
			userInfo: {
				id: "",
				username: "",
				avatar: ""
			}
			
		}
	},
	created() {
		this.getUserInfo()
	},
	methods: {
		getUserInfo() {
			this.$axios.get("/sys/userInfo").then(res => {
				this.userInfo = res.data.data
				// 将用户id 和用户名放入store
				this.$store.commit('setUserId', this.userInfo.id);
				this.$store.commit('setUserName', this.userInfo.username);
			})
		},
		logout() {
			console.log('=======username=======');
			console.log(this.userInfo.username);
			this.$axios.post("/logout")
				.then(res => {
					localStorage.clear()
					sessionStorage.clear()
					this.$store.commit("resetState")
					this.$store.commit("changeMenuWidth",false);
					this.$router.push("/login")
				})
		}
	},
	computed:{
		asideWidth:{
			//get有什么作用？当有人读取fullName时，get就会被调用，且返回值就作为fullName的值
			//get什么时候调用？1.初次读取fullName时。2.所依赖的数据发生变化时。
			get(){
				// console.log(this) //此处的this是vm 
				console.log("=========asideWidth =======");  
				console.log("this.$store.state.menus.menuWidth  :", this.$store.state.menus.menuWidth);  
				return this.$store.state.menus.menuWidth
			}
			//set什么时候调用? 当fullName被修改时。
		},
		mainWidth:{
			get(){
				// console.log(this) //此处的this是vm  
				let wd = this.$store.state.menus.menuWidth;
				console.log("this.$store.state.menus.menuWidth  :", this.$store.state.menus.menuWidth);  
				if(wd==140){
					return 'el-main-width140px'
				}else if(wd==64){
					return 'el-main-width64px'
				}
			}
		}
	}
}
</script>

<style scoped>

#div-parent{
	display:flex;
    justify-content:right;/*主轴上居中*/
}	
#div-head1 {
	margin-top: 6px;
	justify-content:left;/*主轴上居中*/
	float: left;
}
#div-head2 {
	justify-content:right;/*主轴上居中*/
	float: left;
}

/* .el-container {
	padding: 0;
	margin: 0;
	height: 100%;
} */

.global-container{
	padding: 0;
	margin: 0;
	height: 100%;
}

.header-avatar {
	float: right;
	width: 210px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.el-dropdown-link {
	cursor: pointer;
	color: orangered;
}

.el-header {
	/* background-color: rgb(110, 176, 252); */
	/* position: relative; absolute */
	position: relative;
	width:100%;

	background-color: #c2b0f0;
	/* color: #333; */
	/* color: rgb(250, 250, 250); */
	text-align: center;
	font-size: 20px;
	line-height: 60px;
	/* display:flex; */
	/* justify-content: space-between; */
	align-items: center;
}

.el-aside {
	background-color: #6E8CFC;
	/* color: #333; */
	line-height: 200px;
	/* width: 140px; */
	position: absolute;
	left: 0;
	right: 0;
	top: 60px;
	bottom: 0;
	overflow-y: auto;  
	overflow-x: hidden;  
}
.el-main {
	color: #333;
	padding: 0;

	position: absolute;
	left: 140px;
	right: 0;
	top: 60px;
	bottom: 0;
	overflow-y: auto;	
}

.el-main-width64px {
	left: 64px;	
}
.el-main-width140px {
	left: 140px;	
}
/* 
.el-submenu__title{
		font-size: 14px;
		color: #303133;
		padding: 0 5px;
		cursor: pointer;
		transition: border-color .3s,background-color .3s,color .3s;
		box-sizing: border-box;
	} */

.siteLink {
	color: orangered;
}

a {
	text-decoration: none;
}
</style>