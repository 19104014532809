import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import axios from './axios'
import echarts from 'echarts'
import global from './globalFun'

import './dateUtil'
//导入过滤器文件
import './filter.js'

//导入自定义指令文件
import './directive.js'


Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios //
Vue.config.productionTip = false

// require("./mock.js")

Vue.use(Element)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


