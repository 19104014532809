<!-- Home.vue 作为整个单页面应用的页面，包含左侧区域，头部和下面的主页面(由子路由 index 构成) -->
<!-- App.vue 的组件中 路由(/) 对应的组件是 Home.vue,它将在 App.vue 的路由视图中显示 -->
<!-- 整体页面结构：  app.vue -> home.vue 登录后的页面
                                        -> SideMenu.vue
								        -> Tabs.vue
                                    ->index.vue (main部分，作为home的子路由存在)
							-> Login.vue  登录页面
     app.vue 中配置了两个路由，/  和 /login，分别对应显示上述的Home.vue 组件和 Login.vue组件。 
	 axios.js中配置了拦截器，当直接访问 / 时，如果没有获取到认证数据，会自动跳转到 /login 路由。
-->

<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
	export default {
		name: "App",
		watch: {
			$route(to, from) {
				// console.log("to")
				// console.log(to)
				if (to.path != '/login') {
					let obj = {
						name: to.name,
						title: to.meta.title
					}
					this.$store.commit("addTab", obj)
				}
			}
		}
	}
</script>

<style>
	html, body, #app {
		font-family: 'Helvetica Neue', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', 'Microsoft Yahei', sans-serif;
		height: 100%;
		padding: 0;
		margin: 0;
		font-size: 15px;
	}
</style>
