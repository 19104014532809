<template>
     <!-- background-color="#545c64" -->

	 <div>
		<div :class="divToggle"  @click="toggleCollapse">|||
			<!-- <template slot="title">
				<i class="el-icon-s-fold"></i>
				<span slot="title">首页</span>
			</template> -->
		</div>

		<el-menu
				:default-active="this.$store.state.menus.editableTabsValue"
				class="el-menu-vertical-demo"
				background-color= "#6E8CFC"
				text-color="#fff"
				:unique-opened="true"
				:collapse="isCollapse"
				:width="isCollapse?'64px':'140px'"
				:collapse-transition="false"
				active-text-color="#ffd04b">

			<!-- <router-link to="/index"  style="text-decoration:none;">
				<el-menu-item index="Index"  @click="selectMenu({name: 'Index', title: '首页'})">
					<template slot="title">
						<i class="el-icon-s-home"></i>
						<span slot="title">首页</span>
					</template>
				</el-menu-item>
			</router-link> -->

			<el-submenu :key="menu.id" :index="menu.name" v-for="menu in menuList">
				<template slot="title">
					<i :class="setIcon(menu.icon)"></i>
					<span>{{menu.title}}</span>
				</template>

				<router-link  :to="item.path" :key ="item.name" v-for="item in menu.children" style="text-decoration:none;">
					<el-menu-item :index="item.name" @click="selectMenu(item)">
						<template slot="title">
							<i class="el-icon-menu"></i>
							<span slot="title">{{item.title}}</span>
						</template>
					</el-menu-item>
				</router-link>
			</el-submenu>

		</el-menu>
	</div>
</template>

<script>
	export default {
		name: "SideMenu",
		data() {
			return {
				isCollapse: false,
				//默认时为 140 px
				divToggle:['toggle-button','toggle-button-width140'],
				// MenuIcon:''
			}
		},
		computed:  
		{
			menuList: {
				get() {
					return this.$store.state.menus.menuList
				}
			},
			asideWidth: {
				get() {
					return this.$store.state.menus.menuWidth
				}
			},
		},
		methods: {
			setIcon(value){
				return value+' '+'mainIcon'
			},

			selectMenu(item) {
				this.$store.commit("addTab", item)
			},
			toggleCollapse(){
				// console.log("this.asideWidth=======",this.$store.state.menuWidth);
				// console.log("this.menuCollapseWidth======",this.menuCollapseWidth);
				this.isCollapse= !this.isCollapse;
				this.$store.commit("changeMenuWidth",this.isCollapse);
				
				if(this.isCollapse){
					this.divToggle=['toggle-button','toggle-button-width64']
				}else{
					this.divToggle=['toggle-button','toggle-button-width140']
				}
			}
		}
    }
</script>

<style scoped>
	.el-menu{
		border-right: solid 0px;
	}

	.toggle-button{
	   width: 64px;
       font-size: 15px;
	   text-align: center;
	   background-color: #6E8CFC;
	   color: #fafafa;
       line-height: 56px;
	   letter-spacing: 0.3em;
	   cursor: pointer;
	   caret-color: transparent;
	}
	.toggle-button-width64{
	   width: 64x;
	}
	.toggle-button-width140{
	   width: 140px;
	}
	.el-menu--collapse {
    	width: 64px;
		padding: 0px;
		margin: 0px;
	}
	.mainIcon{
		color: rgb(240, 186, 7);
		border: 0px;
	}
	.el-icon-menu{
		color: rgb(255, 255, 255);
	}


	.toggle-button:hover{
		background-color: rgb(88, 112, 202);
		color:rgb(255, 208, 75);
	}
	.el-menu-vertical-demo {
		height: 100%;
	}
	/* .el-menu-vertical-demo /deep/ .el-submenu .el-menu-item{
		padding: 0;
		min-width: 100px;
	} */
</style>