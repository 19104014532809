<template>
  <div class="mini-tab-container">
  <el-tabs
    v-model="editableTabsValue"
    type="card"
    closable
    @tab-remove="removeTab"
    @tab-click="clickTab"
    
  >
    <el-tab-pane
      v-for="(item) in editableTabs"
      :key="item.name"
      :label="item.title"
      :name="item.name"
    ></el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return {
      // editableTabsValue: this.$store.state.menus.editableTabsValue,
      // editableTabs: this.$store.state.menus.editableTabs
    };
  },
  computed: {
    editableTabs: {
      get() {
        return this.$store.state.menus.editableTabs;
      },
      set(val) {
        this.$store.state.menus.editableTabs = val;
      }
    },
    editableTabsValue: {
      get() {
        return this.$store.state.menus.editableTabsValue;
      },
      set(val) {
        this.$store.state.menus.editableTabsValue = val;
      }
    }
  },
  methods: {
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;

      if (activeName === "Index") {
        return;
      }

      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
      this.$router.push({ name: activeName });
    },
    clickTab(target) {
      this.$router.push({ name: target.name });
    }
  }
};
</script>

<style scoped>
/* .el-tabs{
		height: 46px;
	} */

.el-tabs--card {
  height:  48px;
  /* overflow-y: auto; */
}
/* .el-tab-pane {
  height: calc(100vh - 110px);
  overflow-y: auto;
} */

.mini-tab-container /deep/ .el-tabs--card{
  height: 28px;
}
.mini-tab-container /deep/ .el-tabs__header{
  margin: 0;
}
.mini-tab-container /deep/ .el-tabs__item{
  height: 28px;
  line-height: 28px;
}
</style>