import Vue from 'vue'

/**
 * 《全局指令》
   directive提供两个参数
   参数1：关于指令的名称，在定义的时候前面不需要加“v-“前缀，但是在使用的过程中一定要加“v-"前缀
          例如我们现在为自定义指令取的名字叫做dt1(不加v-),那么我们在将来的元素中使用的时候，就是使用v-dt1(加上v-)
   参数2:json对象，在这个对象身上，有一些指令相关的函数，这些函数可以在特定的阶段，执行相关的操作（钩子函数）

   私有指令，在 new Vue中和data 平行的地方定义 directives: { bind: function(el){}, inserted: function(el){} }
  * */
Vue.directive('GetFocus', {
    /**
      以下定义了3个函数，这3个函数是与vuejs.对象生命周期密切相关的函数在每个函数中，第一个参数el(表示element),
      表示的是指令操作的元素. 注意：每一个e1对象，同时也是一个原生js对象(dom对象)

      在未来的实际项目开发中：
          使用bind函数来操作元素的样式(css)
          使用insertedl函数来操作元素的行为(js)
    */

   //bind函数，每当指令绑定到该元素上的时候，会立即执行这个bind函数，该函数只执行一次
   // 参数1: el表示的是指令操作的元素
   // 参数2: bingding，可以不要，是在元素使用指令的时候，为函数传递的一个具体的值,该参数来接收值，通过赋值形式为元素来进行操作。
    bind: function(el,bingding) {

      /**页面加载完毕后，让指定的文本框自动获取焦点执行结果：文本框并没有自动取得焦点在元素刚绑定了指令的时候，还没有插入到dom中去，
       * 这时候调用focus方法一定是没有作用的一个元素，只有插入dom之后，才能够触发dom相关的方法(例如使用focus方法取得焦点)
      */
       el.children[0].style.color=bingding.value;
       //el.children[0].style.color="red";
    },

    //inserted函数：表示元素插入到dom中的时候，会执行该函数，该数只会执行一次
    inserted: function(el) {
      // console.log("---------**********-----------------");
      // console.log(el.children[0]);
      el.children[0].focus(); 
    },

    // update(el, binding, vnode, oldVnode) {},
    // updated 函数：当vuejs 中的元素更新的时候，会触发该函数，元素是可以随时进行更新的，所以该函数也可以触发多次
    updated: function(el) {
 
    } 
   
});