import Vue from 'vue'
import Vuex from 'vuex'
import menus from "./modules/menus";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: '',
		userId: null,
		userName: ''
	},
	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
			localStorage.setItem("token", token)
		},
		setUserId:(state, userId) =>{
			state.userId = userId
			localStorage.setItem("userId", userId)
		},
		setUserName:(state, userName) =>{
			state.userName = userName
			localStorage.setItem("userName", userName)
		}
	},
	actions: {},
	modules: {
		menus
	}
})
