import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
	state: {
		menuList: [],
		permList: [],
		hasRoutes: false,
		editableTabsValue: 'Index',
		editableTabs: [{
			title: '首页',
			name: 'Index',
		}],
		menuWidth:140
		
	},
	mutations: {
		setMenuList(state, menus) {
			state.menuList = menus
		},
		setPermList(state, perms) {
			state.permList = perms
		},
		changeRouteStatus(state, hasRoutes) {
			state.hasRoutes = hasRoutes
		},

		addTab(state, tab) {
			let index = state.editableTabs.findIndex(e => e.name === tab.name)
			if (index === -1) {
				state.editableTabs.push({
					title: tab.title,
					name: tab.name,
				});
			}
			state.editableTabsValue = tab.name;
		},
		changeMenuWidth(state,b){
			if(b){
				state.menuWidth =64
			}else{
				state.menuWidth =140
			}
		},

		resetState: (state) => {
			state.menuList = []
			state.permList = []

			state.hasRoutes = false
			state.editableTabsValue = 'Index'
			state.editableTabs = [{
				title: '首页',
				name: 'Index',
			}]
		}
	},
	actions: {
	},

}